import React from "react"
import Layout from "../components/layout"
import Container from "../components/container"
import Metatags from "../components/Metatags"

const BFCM2020 = () =>{
  return (
    <Layout>

        <Metatags
            title="Black Friday and Cyber Monday Deals | Optinly"
            description = "Explore Black Friday and Cyber Monday Deals of Optinly"
            keywords= {[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url = "https://www.optinly.com"
            pathname = "/wordpress-black-friday-cyber-monday-deals-2020/"
        />
        
        <Container>
            <div className="p-5 jumbotron">
                <h1 className="mt-5 text-center">Deal expired. <br/><br/>Stay tuned for future deals.</h1>
            </div>
        </Container>
    </Layout>
  )
}

export default BFCM2020